import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Stack } from '@mui/system';
import { PythonMonitoringPods } from '@/entities/PythonLib/entities/PythonMonitoringPods/PythonMonitoringPods';

export const PythonLibContent: FC = observer(() => {
  return (
    <Box height='100%' width='100%'>
      <Stack padding={4} flex={1}>
        <PythonMonitoringPods />
      </Stack>
    </Box>
  );
});
PythonLibContent.displayName = 'PythonLibContent';
