import React, { useEffect } from "react";

import { observer } from "mobx-react-lite";
import { container } from "tsyringe";
import { useEdgesState, useNodesState } from "reactflow";
import { Box } from "@mui/system";

import { LoaderIcon } from "@/shared/ui/Icons/LoaderIcon/LoaderIcon";
import { FlowPlatform } from "@/shared/ui/FlowPlatform/FlowPlatform";
import ReactFlowToImageButton from "@/shared/ui/ReactFlowToImageButton";
import { DisplayMessageService, MessageNodeProps } from "@/entities/MessageTracing";

import { MessageNode } from "./MessageNode/MessageNode"; // don't shorten
import { ExpanderNode } from "./ExpanderNode";

const displayMessageService = container.resolve(DisplayMessageService);
const proOptions = {
  hideAttribution: true,
};
const nodeTypes = {
  messageNode: MessageNode,
  expanderNode: ExpanderNode,
};

interface MessagesFlowProps {
  blockId: string;
  messageId: string;
  filter?: { [key: string]: any },
  isTrace?: boolean;
}

export const MessagesFlow = observer(({
  blockId,
  messageId,
  filter = null,
  isTrace = false,
}: MessagesFlowProps) => {
  const [nodes, setNodes] = useNodesState<MessageNodeProps>([]);
  const [edges, setEdges] = useEdgesState([]);
  const { updateData } = displayMessageService;

  useEffect(() => {
    if (!updateData) return;
    setNodes(displayMessageService.messageNodes);
  }, [updateData]);

  useEffect(() => {
    displayMessageService
      .getMessages(blockId, {
        pagination: { size: 1, page: 0 },
        filterList: filter ? [filter] : [],
        addTrace: isTrace,
        messageId,
      })
      .then(() => {
        setNodes(displayMessageService.messageNodes);
        setEdges(displayMessageService.messageEdges);
      });
  }, [filter, blockId, messageId]);

  useEffect(() => {
    if (!displayMessageService.selectedExecutorHistoryId) return;
    setNodes(displayMessageService.messageNodes);
    setEdges(displayMessageService.messageEdges);
  }, [displayMessageService.selectedExecutorHistoryId]);

  if (displayMessageService.isLoadingMessages) {
    return (
      <Box height='100%' alignItems='center' justifyContent='center' display='flex'>
        <LoaderIcon width={24} height={24} />
      </Box>
    );
  }
  return (
    <>
      {!displayMessageService.exchangeList && <p>Сообщения отсутствуют</p>}
      <Box height='100%'>
        <FlowPlatform
          nodeTypes={nodeTypes}
          proOptions={proOptions}
          nodes={nodes}
          edges={edges}
          defaultViewport={{ x: 0, y: 50, zoom: 0.75 }}
          onlyRenderVisibleElements={nodes.length > 30}
          translateExtent={[
            [-100, -100],
            [
              (displayMessageService.maxWidth || Infinity) + 1000,
              (displayMessageService.maxHeight || Infinity) + 1000,
            ],
          ]}
          className='messageTracing'
        >
          {!!nodes.length && nodes.length <= 30 && (
            <ReactFlowToImageButton
              selector='.messageTracing'
              imageWidth={displayMessageService.maxWidth || 1000}
              imageHeight={displayMessageService.maxHeight || 1000}
            />
          )}
        </FlowPlatform>
      </Box>
    </>
  );
});
