import { FC } from "react";

import { debounce } from "lodash";
import { container } from "tsyringe";
import { observer } from "mobx-react-lite";
import { Stack, Grid } from "@mui/material";
import { InputProps } from "@mui/material/Input/Input";

import { Input } from "@/shared/ui";
import { UserService, UserStore } from "@/entities/User";
import { CreateUserButton } from "@/features/CreateUser";
import { RequestAdminRightButton } from "@/features/RequestAdminRight";


const userService = container.resolve(UserService);
const userStore = container.resolve(UserStore);

export const UserSearch: FC = observer(() => {

  const searchLogin: InputProps['onChange'] = ({ target: { value } }) => {
    userService.resetUsers();
    userService.search = value;
    userService.getUsers(0);
  };
  const handleChange = debounce(searchLogin, 400);

  return (
    <Stack direction='row' gap={2.5}>
      <Grid container spacing={2.5} alignItems='center'>
        <Grid item xs={12} md={3} lg={2}>
          <Input
            placeholder="Поиск"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={8} />
        <Grid item xs={12} md={3} lg={2}>
          {userStore.isNotRight ? <RequestAdminRightButton /> : <CreateUserButton />}
        </Grid>
      </Grid>
    </Stack>
  );
});