import React from 'react';

import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
  SettingOutlined,
  LinkOutlined,
  ApiOutlined,
  KeyOutlined,
  BankOutlined,
  NotificationOutlined,
  AuditOutlined,
  TeamOutlined,
  SolutionOutlined,
  PythonOutlined,
  ToolOutlined,
  CrownOutlined,
} from '@ant-design/icons';
import { PythonBeanPage } from '@/pages/PythonBeanPage';
import { UpsertSettingsForm } from '@/features/UpsertSettings';
import { CompanySettings } from '@/pages/CompanySettings';
import { NotificationPage } from '@/pages/NotificationPage';
import { UsersPage } from '@/pages/UsersPage';
import { RolesPage } from '@/pages/RolesPage';
import { ExternalAppsPage } from '@/pages/ExternalAppsPage';
import { SettingsUserAuthPage } from '@/pages/SettingsUserAuthPage';
import { KeyValueStorePage } from '@/pages/KeyValueStorePage';
import { OpenApiPage } from '@/entities/OpenApi/pages/OpenApiPage/OpenApiPage';

import { PythonLibsPage } from '@/entities/PythonLib/pages/PythonLibsPage';
import useLocalStorageRedux from '@/hooks/useLocalStorageRedux';
import { TenantPage } from '@/entities/Tenant/pages/TenantPage';
import { SettingsDrawer } from "./SettingsDrawer";


const linkBuilder = (domain: string) => (path: string) => `${domain}settings/${path}`;

const sx = { padding: '32px', height: '100%' };

export function SettingsPage() {
  const serverPath = useSelector((state) => state.location.serverPath);
  const getLink = linkBuilder(serverPath);
  const [companyId] = useLocalStorageRedux('companyId');

  const items = [
    {
      label: 'Основные',
      icon: SettingOutlined,
      link: getLink('global'),
    },
    {
      label: 'Внешние сервисы',
      icon: LinkOutlined,
      link: getLink('externalService'),
    },
    {
      label: 'OpenAPI-сервисы',
      icon: ApiOutlined,
      link: getLink('openApiService'),
    },
    {
      label: 'KeyValue Store',
      icon: KeyOutlined,
      link: getLink('keyValueStore'),
    },
    {
      label: 'Компания',
      icon: BankOutlined,
      link: getLink('company'),
    },
    {
      label: 'Уведомления',
      icon: NotificationOutlined,
      link: getLink('notifications'),
    },
    {
      label: 'Учетные системы',
      icon: AuditOutlined,
      link: getLink('auth'),
    },
    {
      label: 'Пользователи',
      icon: TeamOutlined,
      link: getLink('users'),
    },
    {
      label: 'Роли пользователей',
      icon: SolutionOutlined,
      link: getLink('roles'),
    },
    {
      label: 'Тенанты',
      icon: CrownOutlined,
      link: getLink('tenants'),
      predicate: () => companyId === 'default',
    },
    {
      label: 'Python скрипты',
      icon: PythonOutlined,
      link: getLink('pythonBeans'),
    },
    {
      label: 'Управление Python обработчиком',
      icon: ToolOutlined,
      link: getLink('pythonLibs'),
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: 'calc(100vh - 96px)',
        overflow: 'hidden',
      }}
    >
      <SettingsDrawer
        items={items}
      />
      <Box component="main" sx={{ flexGrow: 1, p: 3, width: 'calc(100vw - 240px)' }}>
        <Routes>
          <Route path='' element={<Navigate to='global' replace />} />
          <Route
            path='global'
            element={(
              <Paper elevation={0} sx={sx}>
                <UpsertSettingsForm />
              </Paper>
            )}
          />
          <Route
            path='externalService'
            element={(
              <Paper elevation={0} sx={sx}>
                <ExternalAppsPage />
              </Paper>
            )}
          />
          <Route
            path='openApiService'
            element={(
              <Paper elevation={0} sx={sx}>
                <OpenApiPage />
              </Paper>
            )}
          />
          <Route
            path='keyValueStore'
            element={<KeyValueStorePage />}
          />
          <Route path='pythonBeans' element={<PythonBeanPage hideChooseScriptButton />} />
          <Route path='pythonLibs' element={<PythonLibsPage />} />
          <Route path='auth' element={<SettingsUserAuthPage />} />
          <Route path='users' element={<UsersPage />} />
          <Route
            path='roles'
            element={(
              <Paper elevation={0} sx={sx}>
                <RolesPage />
              </Paper>
            )}
          />
          {companyId === 'default' && <Route path='tenants' element={<TenantPage />} />}
          <Route
            path='company'
            element={(
              <Paper elevation={0} sx={sx}>
                <CompanySettings />
              </Paper>
            )}
          />
          <Route path='notifications' element={<NotificationPage />} />
        </Routes>
      </Box>
    </Box>
  );
}
