import React, { FC } from 'react';

import { NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import MuiDrawer from '@mui/material/Drawer';
import MenuList from '@mui/material/MenuList';
import MenuItem from "@mui/material/MenuItem";
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import styles from './SettingsDrawer.module.scss';

const drawerWidth = 240;
const iconSize = 20;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
  }),
);

interface ItemMenu {
  label: string;
  link: string;
  icon: FC;
  predicate?: () => boolean;
}

interface SettingsDrawerProp {
  items: ItemMenu[];
}

export function SettingsDrawer ({
  items,
}: SettingsDrawerProp) {
  const location = useLocation();
  const activePath = location.pathname;
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const isLtr = theme.direction !== 'rtl';
  const ToggleIcon = open && isLtr ? MenuFoldOutlined : MenuUnfoldOutlined;

  return (
    <Drawer
      variant="permanent"
      open={open}
      className={styles.drawer}
    >
      <DrawerHeader className={cn(styles['drawer-header'], { open })}>
        <Typography>Настройки</Typography>
        <IconButton
          onClick={toggleOpen}
        >
          <ToggleIcon style={{ fontSize: iconSize }} />
        </IconButton>
      </DrawerHeader>
      <MenuList>
        {items.map(({
          label,
          link,
          icon: Icon = QuestionCircleOutlined,
          predicate,
        }) => {
          if (predicate && !predicate()) return null;

          return (
            <MenuItem
              key={label}
              className={styles['menu-item']}
            >
              <ListItemButton
                className={cn({
                  expand: open,
                  active: activePath === link,
                })}
                component={NavLink}
                to={link}
              >
                <ListItemIcon
                  className={styles.icon}
                >
                  <Icon style={{ fontSize: iconSize }} />
                </ListItemIcon>
                <Typography
                  className={styles.label}
                  variant="inherit"
                  noWrap
                >
                  {label}
                </Typography>
              </ListItemButton>
            </MenuItem>
          )
        })}
      </MenuList>
    </Drawer>
  );
}
