import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { singleton } from 'tsyringe';
import { isArray, assign, get } from 'lodash';
import { Edge, Node } from "reactflow";

import { notify } from '@/shared/ui/Toast/notify';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { normalize } from '@/shared/lib/normalize';
import { findExecutorHistoryByTracing } from "@/shared/lib/executorHistoryUtils";
import { AllConnectService } from "@/entities/Connect/services/AllConnectService";
import { DisplayMessageRequest, DisplayMessageResponse, ExecutorHistory } from '@/entities/Connect/types';
import { enrichList } from "../adapters/enrichExchangeList";
import { DisplayMessageStore } from '../model/DisplayMessageStore';
import { MessageNodeProps, MessagesTree, Tabs } from "../model/types";
import { buildMessageNode } from "../adapters/buildMessageNode";
import { buildMessageEdge } from "../adapters/buildMessageEdge";
import { collapseNodes } from "../adapters/collapseNodes";

@singleton()
export class DisplayMessageService {
  constructor(
    private displayMessageStore: DisplayMessageStore,
    private apiService: ApiService,
    private allConnectService: AllConnectService,
  ) {
    makeAutoObservable(this);
  }

  get isLoadingMessages() {
    return this.displayMessageStore.isLoadingMessages;
  }

  get blockTree() {
    return this.displayMessageStore.blockTree;
  }

  get messageNodes() {
    if (!this.displayMessageStore.messagesTree) return [];
    return toJS(this.displayMessageStore.messagesTree.nodes);
  }

  get messageEdges() {
    if (!this.displayMessageStore.messagesTree) return [];
    return toJS(this.displayMessageStore.messagesTree.edges);
  }

  get exchangeList() {
    return this.displayMessageStore.exchangeList;
  }

  get relationSet() {
    return this.displayMessageStore.relationSet;
  }

  get canvas() {
    return this.displayMessageStore.canvas;
  }

  get messageNumber() {
    return this.displayMessageStore.messageNumber;
  }

  get messageTotal() {
    return this.displayMessageStore.messageTotal;
  }

  get selectedExecutorHistoryId() {
    return this.displayMessageStore.selectedExecutorHistoryId;
  }

  get selectedExecutorHistory() {
    return this.displayMessageStore.selectedExecutorHistory;
  }

  get isResendLoading() {
    return this.displayMessageStore.isResendLoading;
  }

  get activeTab() {
    return this.displayMessageStore.activeTab;
  }

  get maxHeight() {
    return this.displayMessageStore.maxHeight;
  }

  get maxWidth() {
    return this.displayMessageStore.maxWidth;
  }

  get executorHistory() {
    return this.displayMessageStore.executorHistory;
  }

  get updateData() {
    return this.displayMessageStore.updateData
  }

  setActiveTab(tab:Tabs) {
    this.displayMessageStore.activeTab = tab
  }

  setExecutorHistory(executorHistory: ExecutorHistory) {
    const cfg = this.buildCfg(executorHistory);
    this.setupCfg(cfg);
  }

  reset() {
    this.displayMessageStore.canvas = null;
    this.displayMessageStore.messages = null;
    this.displayMessageStore.exchangeList = null;
    this.displayMessageStore.relationSet = [];
    this.displayMessageStore.messageNumber = 0;
    this.displayMessageStore.messageTotal = 0;
    this.displayMessageStore.selectedExecutorHistoryId = null;
    this.displayMessageStore.selectedExecutorHistory = null;
    this.displayMessageStore.isResendLoading = false;
    this.displayMessageStore.activeTab = Tabs.tracing
    this.displayMessageStore.maxHeight = undefined;
    this.displayMessageStore.maxWidth = undefined;
    this.displayMessageStore.executorHistory = undefined
  }


  buildMsgTree(exchangeList, canvas, relationSet): MessagesTree {
    if (!exchangeList) return { nodes: [], edges: [] };

    const nodes: Node<MessageNodeProps>[] = exchangeList.ids.map((id: string) => {
      const exchangeData = exchangeList!.entities[id];
      const buildDownloadHandler = (type: string) => {
        const { bodyDataComponentId, isSuccessDownload } = exchangeData[type] || {};
        if (!bodyDataComponentId || isSuccessDownload) return undefined;
        return () => {
          this.downloadBodyData(id, bodyDataComponentId, type);
        };
      }
      const canvasData = canvas!.entities[id];
      return buildMessageNode({
        exchangeData,
        canvasData,
        allConnectService: this.allConnectService,
        buildDownloadHandler,
      });
    });
    const edges: Edge[] = relationSet.map(buildMessageEdge);

    return collapseNodes(nodes, edges);
  };

  buildCfg(executorHistory: ExecutorHistory | null): { [key: string]: any } {
    if (!executorHistory) {
      return {
        exchangeList: null,
        relationSet: [],
        canvas: null,
        selectedExecutorHistory: null,
        selectedExecutorHistoryId: null,
      };
    }
    const { graphTrace } = executorHistory;
    const { relationSet = [] } = graphTrace;
    const exchangeList = normalize(enrichList(graphTrace), 'id');
    const canvas = normalize(graphTrace.canvas.elements, 'elementId');
    const messagesTree = this.buildMsgTree(exchangeList, canvas, relationSet);

    const cfg = {
      exchangeList,
      relationSet,
      canvas,
      messagesTree,
      maxHeight: graphTrace.canvas.height,
      maxWidth: graphTrace.canvas.width,
      selectedExecutorHistory: executorHistory,
      selectedExecutorHistoryId: executorHistory.id,
      activeTab: Tabs.tracing,
    };
    if (executorHistory.executorLogId) {
      delete cfg.activeTab;
    }
    return cfg;
  }

  setupCfg(cfg: { [key: string]: any }) {
    assign(this.displayMessageStore, cfg);
  }

  setBodyData(exchangeId: string, value: string, type: string) {
    const exchangeNode = get(this.displayMessageStore, ['exchangeList', 'entities', exchangeId]);
    const { nodes } = this.displayMessageStore.messagesTree;
    const node = nodes.find(({ id }) => id === exchangeId);
    node.data.value = value;
    const key = type === 'inputBody' ? 'inputBody' : 'body';
    exchangeNode[key].outputBody = value;
    exchangeNode[key].isSuccessDownload = true;
  }

  async getMessages(blockId: string, body: DisplayMessageRequest) {
    this.displayMessageStore.isLoadingMessages = true;

    try {
      const response = await this.apiService.instance.post<DisplayMessageResponse>(
        `/editor/displayMessage/block/${blockId}`,
        { ...body }
      );

      const { content, number, totalElements } = response.data.executorHistoryPage;
      if (!isArray(content) || !content.length) {
        runInAction(() => {
          this.reset();
        })
        return;
      }
      const [exHistory] = content;
      const { defaultExecutorHistoryIdByTracing } = exHistory;
      const history = defaultExecutorHistoryIdByTracing
        ? findExecutorHistoryByTracing(exHistory, defaultExecutorHistoryIdByTracing)
        : exHistory;
      const cfg = this.buildCfg(history);
      runInAction(() => {
        this.displayMessageStore.executorHistory = exHistory;
        this.setupCfg(cfg);
        this.displayMessageStore.messageNumber = number + 1;
        this.displayMessageStore.messageTotal = totalElements;
      });
    } catch (error) {
      notify.error('Не удалось получить трассировку сообщений');
      throw error;
    } finally {
      this.displayMessageStore.isLoadingMessages = false;
    }
  }

  async resendMessage(executorHistoryId: string) {
    this.displayMessageStore.isResendLoading = true;
    try {
      const response = await this.apiService.instance.post(`/editor/monitoring/execute/${executorHistoryId}`);
      const cfg = this.buildCfg(response.data);
      runInAction(() => {
        this.setupCfg(cfg);
      });
    } catch (error) {
      notify.error('Не удалось переотправить сообщение');
      throw error;
    } finally {
      this.displayMessageStore.isResendLoading = false;
    }
  }

  async downloadBodyData(exchangeId: string, bodyDataComponentId: string, type: string) {
    try {
      const response = await this.apiService.instance.get(`/editor/bodyData/${bodyDataComponentId}`);

      runInAction(() => {
        this.setBodyData(exchangeId, response.data.data, type)
        this.displayMessageStore.updateData = new Date().getTime()
      });
    } catch (error) {
      notify.error('Не удалось выполнить загрузку');
      throw error;
    }
  }
}
